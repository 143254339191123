import {EventEmitter, Injectable} from '@angular/core';
import {BaseService} from './base.service';


@Injectable()
export class GlobalService extends BaseService {

    private _loadingQueue: string[] = [];
  loggedUser: any;
  router: any;

    get isLoading(): boolean {
        return this._loadingQueue?.length > 0;
    }

    public loading = false;
    public orientation;

    public onSelectedNoticeChange = new EventEmitter();

    public get selectedNotice() {
        const result = localStorage.getItem('selectedNotice');
        if (result) {
            return JSON.parse(result);
        }
        return null;
    }

    public set selectedNotice(value) {
        if (value) {
            localStorage.setItem('selectedNotice', JSON.stringify(value));
        } else {
            localStorage.removeItem('selectedNotice');
        }
        this.onSelectedNoticeChange.emit(value);
    }

    convertToDataURLviaCanvas(url, outputFormat) {
        return new Promise( (resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function() {
                console.log('img', img);
                let canvas = <HTMLCanvasElement> document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                dataURL;
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                // callback(dataURL);
                canvas = null;
                resolve(dataURL);
            };
            img.src = 'https://cors-anywhere.herokuapp.com/' + url;
        });
    }


  public startLoading(message: string = ''): void {
    setTimeout(() => {
      this._loadingQueue.push(message);
    }, 10);
  }

  public stopLoading(): void {
    setTimeout(() => {
      this._loadingQueue.pop();
    }, 10);
  }


  public async uploadResource(id: number, data: any, body: { texto: string, arquivo: string }) {
    const url = `https://api.tecnologiaasap.com.br/api/v2/admin/notice/orientation/recurso/${id}&user=${data.client}&type=usuario`;
    try {
      return await this.http.post(url, body).toPromise();
    } catch (error) {
      console.error('Erro ao enviar recurso:', error);
      throw error;
    }
  }

  public getResource(id: number, data: any,) {
    const url = `https://api.tecnologiaasap.com.br/api/v2/admin/notice/orientation/recurso/${id}&user=${data.client}&type=usuario`;
    try {
      return this.http.get(url);
    } catch (error) {
      console.error('Erro ao obter recurso:', error);
      throw error;
    }
  }


}
