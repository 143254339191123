import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Title } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
import { ClienteService } from './services/cliente.service';
import { AsapClientPipe } from './@core/pipes/asap-client.pipe';
import { GlobalService } from './services/global.service';

declare var $: any;

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
  providers: [AuthService, ClienteService]
})
export class AppComponent implements OnInit {

  private _router: Subscription;

  constructor(
    private auth: AuthService,
    private client: ClienteService,
    public global: GlobalService,
    public title: Title,
    public router: Router,
  ) {
    console.log('Iniciando o AppComponent e Buscando o Tema do Cliente', new Date());
    let host = window.location.hostname;
    if (host === 'localhost' || host === 'www.asap.local') {
      // host = 'phi.editalasap.com.br';
       //host = 'editalsmartfit.com.br';
       host = "ctg.edital.hmgasap.com.br";
      // host = "inscricao.jornadaamazonia.com.br";
      // host = 'paulogustavo.edital.hmgasap.com.br';
       //host = 'editalgdf.edital.hmgasap.com.br';
      // host = 'editalsocial.edital.hmgasap.com.br';
    }
    
    this.global.startLoading();
    this.client.getColorByHost(host).subscribe(response => {
      console.log('Color Theme:', response);
      const dictionary = response.data.config;
      if (dictionary) {
        this.auth.syncActiveClient(response.data.client_id);
        AsapClientPipe.setDictionary(dictionary);
        const html = document.querySelector('html');
        html.style.setProperty(
          '--main-color',
          AsapClientPipe.dictionary.mainColor
        );
        setTimeout(() => {
          this.title.setTitle(dictionary.appName);
          this.global.stopLoading();
        }, 100);
        
        this.setFaviconByUrl(response.data.config.favicon);
        
      } else {
        this.router.navigate(['not-found']);
        setTimeout(() => {
          this.title.setTitle('ASAP - EAD: Cliente não Encontrado!');
          this.global.stopLoading();
        }, 100);
      }
    });
  }

  ngOnInit() {
    $.material.options.autofill = true;
    $.material.init();
    this._router = this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationStart) {
        this.global.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.global.loading = false;
        setTimeout(() => {
          if (window.outerWidth > 991) {
            window.document.children[0].scrollTop = 0;
          } else {
            window.document.activeElement.scrollTop = 0;
          }
        }, 100);
      }
    });
  }

    private setFaviconByUrl(faviconUrl: string): void {
      $('#favicon').attr('href', faviconUrl);
    }
}
